import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from "sweetalert2";
import RichTextMarkdown from '../utils/RichTextMarkdown';
import {validateEmail, isObjectEmpty, detectHowManyCodesEntered, isEmptyHtmlEntity} from "../utils/HelpfulFunction";
const google = window.google;
let geocoder = new google.maps.Geocoder();
const isValidDomain = require('is-valid-domain');

class SetUpLoginVariables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tenantVariables: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleRichTextChange = this.handleRichTextChange.bind(this);
    }

    componentDidMount() {
        this.tenantVariablesRef = base.syncState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then() {
                const tenantVariables = this.state.tenantVariables;
                let advanced = false;
                if (tenantVariables.doNotCollectEmail || tenantVariables.sanitizeEmails || tenantVariables.allowList || tenantVariables.blockList || tenantVariables.collectDistance || tenantVariables.noMandatoryTermsAndConditions || tenantVariables.collectYesNoQuestion || tenantVariables.keepFansLoggedIn || tenantVariables.collectCustomDropdown || tenantVariables.collectTextQuestion || tenantVariables.collectTextInputOne || tenantVariables.collectTextInputTwo) {
                    advanced = true;
                }
                if (tenantVariables) {
                    this.setState({
                        acceptableDistance: tenantVariables.acceptableDistance,
                        customDropdownOptions: tenantVariables.customDropdownOptions,
                        locationPermissionsBody: tenantVariables.locationPermissionsBody,
                        locationPermissionsHeader: tenantVariables.locationPermissionsHeader,
                        formattedAddress: tenantVariables.formattedAddress,
                        notAcceptableLocationMessage: tenantVariables.notAcceptableLocationMessage || "",
                        yesNoQuestionText: tenantVariables.yesNoQuestionText || "",
                        notAcceptableLocationHeader: tenantVariables.notAcceptableLocationHeader,
                        canPlayOutside: tenantVariables.canPlayOutside,
                        allowList: tenantVariables.allowList,
                        blockList: tenantVariables.blockList,
                        promotionText: tenantVariables.promotionText || "",
                        promotionTextTwo: tenantVariables.promotionTextTwo || "",
                        promotionTextThree: tenantVariables.promotionTextThree || "",
                        promotionTextFour: tenantVariables.promotionTextFour || "",
                        advanced: advanced
                    })
                }
            }
        });
        this.setState({
            loading: false
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.tenantVariablesRef);
    }

    handleChange(evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleTextChange(evt) {
        const tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[evt.target.name] = evt.target.value;
        this.setState({ tenantVariables: tenantVariablesCopy });
    }

    handleRichTextChange(name, text) {
        const tenantVariablesCopy = this.state.tenantVariables;
        if (isEmptyHtmlEntity(text)) {
            text = "";
        }
        tenantVariablesCopy[name] = text;
        this.setState({
            tenantVariables: tenantVariablesCopy
        });
    }

    selectOption(button) {
        const tenantVariablesCopy = this.state.tenantVariables || {};
        tenantVariablesCopy[button] = !tenantVariablesCopy[button];
        this.setState({ tenantVariables: tenantVariablesCopy });
    }

    updateDistance() {
        const tenantVariables = this.state.tenantVariables || {};
        if (!this.state.acceptableDistance || !this.state.formattedAddress) {
            swal({
                title: 'Hold on!',
                text: "Please make sure all the form fields are filled out",
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        if (this.state.acceptableDistance <= 0) {
            swal({
                title: 'Hold on!',
                text: "Please make sure distance is greater than 0",
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        const canPlayOutside = this.state.canPlayOutside || false;
        this.setState({ loading: true });
        const vm = this;
        let notAcceptableLocationMessage = this.state.notAcceptableLocationMessage;
        if (notAcceptableLocationMessage === "<p><br></p>") {
            notAcceptableLocationMessage = "";
        }
        geocoder.geocode({ 'address': this.state.formattedAddress }, function (results, status) {
            if (status === 'OK') {
                if (results.length === 1) {
                    const tenantVariablesCopy = tenantVariables;
                    tenantVariablesCopy["formattedAddress"] = vm.state.formattedAddress;
                    tenantVariablesCopy["acceptableDistance"] = vm.state.acceptableDistance;
                    tenantVariablesCopy["longitude"] = results[0].geometry.location.lng();
                    tenantVariablesCopy["latitude"] = results[0].geometry.location.lat();
                    tenantVariablesCopy["locationPermissionsBody"] = vm.state.locationPermissionsBody || "";
                    tenantVariablesCopy["locationPermissionsHeader"] = vm.state.locationPermissionsHeader || "";
                    tenantVariablesCopy["notAcceptableLocationMessage"] = notAcceptableLocationMessage;
                    tenantVariablesCopy["notAcceptableLocationHeader"] = vm.state.notAcceptableLocationHeader || "";
                    tenantVariablesCopy["canPlayOutside"] = canPlayOutside;
                    vm.setState({
                        tenantVariables: tenantVariablesCopy,
                        loading: false
                    });
                    swal({
                        title: 'Updates Successful',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    });
                } else {
                    vm.setState({ loading: false });
                    swal({
                        title: 'Hold on!',
                        text: "Too many locations have that address! Add more detail to get only 1 address",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            } else {
                vm.setState({ loading: false });
                swal({
                    title: 'Hold on!',
                    text: 'Finding address was not successful because ' + status,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                });
            }
        });
    }

    updateList(listToUpdate) {
        const listName = listToUpdate + 'List';
        let listToSave = this.state[listName];
        listToSave = listToSave.trim().toLowerCase();
        let rejoinedCleanedDomainsEmails = "";
        if (listToSave) {
            const splitList = listToSave.split(/[ ,\s]+/);
            for (const splitListIndex in splitList) {
                const splitItem = splitList[splitListIndex];
                let valid;
                if (splitItem.indexOf('@') === -1) {
                    valid = isValidDomain(splitItem);
                } else {
                    valid = validateEmail(splitItem);
                }
                if (!valid) {
                    swal({
                        title: 'Hold on!',
                        text: "One of the items on the block list isn't a valid email or domain. The item causing an issue is: " + splitItem + ".",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    });
                    return;
                }
            }
            rejoinedCleanedDomainsEmails = splitList.join(" ");
        }
        base.update(`tenantVariables`, {
            data: { [listName]: rejoinedCleanedDomainsEmails },
            then(err) {
                if (err) {
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    });
                } else {
                    swal({
                        title: 'List Updated',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    });
                }
            }
        });
    }

    async updateCustomDropdownOptions() {
        const returnedArray = detectHowManyCodesEntered(this.state.customDropdownOptions, false, true);
        const responseToAlert = await swal({
            title: 'Save Dropdown Options?',
            text: 'Are you sure you want to do this?  We have detected ' + returnedArray.length.toString() + ' potential responses',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Save',
        });
        if (responseToAlert.value) {
            base.update(`tenantVariables`, {
                data: { customDropdownOptions: this.state.customDropdownOptions },
                then(err) {
                    if (err) {
                        swal({
                            title: 'There was some error!',
                            text: 'Try again and if the problem persists try logging out and logging back in',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        });
                    } else {
                        swal({
                            title: 'Updated',
                            type: 'success',
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: true,
                            timer: 10000
                        });
                    }
                }
            });
        }
    }

    render() {
        const variables = this.state.tenantVariables || {};
        const collectName = variables.collectName;
        const collectIpAddress = variables.collectIpAddress;
        const collectYesNoQuestion = variables.collectYesNoQuestion;
        const collectYesExplanation = variables.collectYesExplanation;
        const nameInEmail = variables.nameInEmail;
        const noMandatoryTermsAndConditions = variables.noMandatoryTermsAndConditions;
        const collectZipCode = variables.collectZipCode;
        const collectOptIn = variables.collectOptIn;
        const collectOptInTwo = variables.collectOptInTwo;
        const collectOptInThree = variables.collectOptInThree;
        const collectOptInFour = variables.collectOptInFour;
        const optInDefaultUnchecked = variables.optInDefaultUnchecked;
        const collectOptInTwoNotDefaultCheck = variables.collectOptInTwoNotDefaultCheck;
        const collectOptInThreeNotDefaultCheck = variables.collectOptInThreeNotDefaultCheck;
        const collectOptInFourNotDefaultCheck = variables.collectOptInFourNotDefaultCheck;
        const collectBirthday = variables.collectBirthday;
        const collectPhoneNumber = variables.collectPhoneNumber;
        const formBirthday = variables.formBirthday;
        const optionalPhoneNumber = variables.optionalPhoneNumber;
        const optionalPhoto = variables.optionalPhoto;
        const optionalHowYouHeard = variables.optionalHowYouHeard;
        const doNotCollectEmail = variables.doNotCollectEmail;
        const sanitizeEmails = variables.sanitizeEmails;
        const collectDistance = variables.collectDistance;
        const collectFullAddress = variables.collectFullAddress;
        const collectTextInput = variables.collectTextInput;
        const collectTextInput2 = variables.collectTextInput2;
        const collectTextInputOne = variables.collectTextInputOne;
        const collectTextInputTwo = variables.collectTextInputTwo;
        const collectTextQuestion = variables.collectTextQuestion;
        const collectTextQuestion2 = variables.collectTextQuestion2;
        const collectTextInputOneLabel = variables.collectTextInputOneLabel;
        const collectTextInputTwoLabel = variables.collectTextInputTwoLabel;
        const collectSocialHandle = variables.collectSocialHandle;
        const keepFansLoggedIn = variables.keepFansLoggedIn;
        const collectHowYouHeard = variables.collectHowYouHeard;
        const collectStreet = variables.collectStreet;
        const collectCity = variables.collectCity;
        const collectState = variables.collectState;
        const collectOtherEmail = variables.collectOtherEmail;
        const collectOtherName = variables.collectOtherName;
        const collectPhoto = variables.collectPhoto;
        const collectCustomDropdown = variables.collectCustomDropdown;
        const canPlayOutside = this.state.canPlayOutside;
        const acceptableDistance = parseFloat(this.state.acceptableDistance);
        const doNotVerifyAmericanZipCode = variables.doNotVerifyAmericanZipCode;
        const verifyCanadianZipCode = variables.verifyCanadianZipCode;
        let formattedAddress = this.state.formattedAddress;
        let locationPermissionsBody = this.state.locationPermissionsBody;
        let locationPermissionsHeader = this.state.locationPermissionsHeader;
        const notAcceptableLocationMessage = this.state.notAcceptableLocationMessage || "";
        const yesNoQuestionText = this.state.yesNoQuestionText || "";
        const notAcceptableLocationHeader = this.state.notAcceptableLocationHeader;

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="container-out container-left-rules" style={{ float: "left", width: "50%" }}>
                        <div className="admin-form-box">
                            <div className="form-group">
                                <label htmlFor="emailPlaceHolderText">Email Placeholder Text</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which name to enter</p>
                                <input value={variables.emailPlaceHolderText} className="form-control" type="text" id="emailPlaceHolderText" name="emailPlaceHolderText" placeholder="Email" onChange={this.handleTextChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="rulesAndRegsText">Collect Name Of Fan</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY name field to the login flow</p>
                                <input type="checkbox" checked={collectName} id="collectName" name="collectName" onClick={() => this.selectOption("collectName")} />
                            </div>
                            {collectName &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="rulesAndRegsText">Include Name In Email</label>
                                        <br />
                                        <input type="checkbox" checked={nameInEmail} id="nameInEmail" name="nameInEmail" onClick={() => this.selectOption("nameInEmail")} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="namePlaceHolderText">Name Placeholder Text</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which name to enter</p>
                                        <input value={variables.namePlaceHolderText} className="form-control" type="text" id="namePlaceHolderText" name="namePlaceHolderText" placeholder="First & Last Name" onChange={this.handleTextChange} />
                                    </div>
                                </>
                            }
                            <div className="form-group">
                                <label htmlFor="collectOptIn">Collect ZipCode</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY zip code field to the login flow</p>
                                <input type="checkbox" checked={collectZipCode} id="collectZipCode" name="collectZipCode" onClick={() => this.selectOption("collectZipCode")} />
                            </div>
                            {collectZipCode &&
                                <div className="form-group">
                                    <label htmlFor="zipCodePlaceholder">Zipcode Placeholder Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which zip to enter</p>
                                    <input value={variables.zipCodePlaceholder} className="form-control" type="text" id="zipCodePlaceholder" name="zipCodePlaceholder" placeholder="Zipcode" onChange={this.handleTextChange} />
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectStreet">Collect Street</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY street field to the login flow</p>
                                <input type="checkbox" checked={collectStreet} id="collectStreet" name="collectStreet" onClick={() => this.selectOption("collectStreet")} />
                            </div>
                            {collectStreet &&
                                <div className="form-group">
                                    <label htmlFor="streetPlaceholder">Street Placeholder Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which street to enter</p>
                                    <input value={variables.streetPlaceholder} className="form-control" type="text" id="streetPlaceholder" name="streetPlaceholder" placeholder="Street" onChange={this.handleTextChange} />
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectCity">Collect City</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY city field to the login flow</p>
                                <input type="checkbox" checked={collectCity} id="collectCity" name="collectCity" onClick={() => this.selectOption("collectCity")} />
                            </div>
                            {collectCity &&
                                <div className="form-group">
                                    <label htmlFor="cityPlaceholder">City Placeholder Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which city to enter</p>
                                    <input value={variables.cityPlaceholder} className="form-control" type="text" id="cityPlaceholder" name="cityPlaceholder" placeholder="City" onChange={this.handleTextChange} />
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectState">Collect State</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY state field to the login flow</p>
                                <input type="checkbox" checked={collectState} id="collectState" name="collectState" onClick={() => this.selectOption("collectState")} />
                            </div>
                            {collectState &&
                                <div className="form-group">
                                    <label htmlFor="statePlaceholder">State Placeholder Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which state to enter</p>
                                    <input value={variables.statePlaceholder} className="form-control" type="text" id="statePlaceholder" name="statePlaceholder" placeholder="State" onChange={this.handleTextChange} />
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectFullAddress">Collect Full Address</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY address field to the login flow</p>
                                <input type="checkbox" checked={collectFullAddress} id="collectFullAddress" name="collectFullAddress" onClick={() => this.selectOption("collectFullAddress")} />
                                <div style={{ display: collectFullAddress ? "" : "none", margin: 5 }}>
                                    <div className="form-group">
                                        <label htmlFor="fullAddressPlaceHolderText">Placeholder Text</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter the placeholder text you want to appear in the input box</p>
                                        <input placeholder="Mailing Address" type="text" id="fullAddressPlaceHolderText" name="fullAddressPlaceHolderText" className="form-control" value={variables.fullAddressPlaceHolderText} onChange={this.handleTextChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="collectBirthday">Collect Birthday</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY birthday input field</p>
                                <input type="checkbox" checked={collectBirthday} id="collectBirthday" name="collectBirthday" onClick={() => this.selectOption("collectBirthday")} />
                                <div style={{ display: collectBirthday ? "" : "none", margin: 5 }}>
                                    <div className="form-group" style={{ display: collectBirthday ? "" : "none" }}>
                                        <label htmlFor="allowedAge">Age</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter an age that the user must be above to play</p>
                                        <input type="number" id="allowedAge" name="allowedAge" className="form-control" value={variables.allowedAge} onChange={this.handleTextChange} />
                                    </div>
                                    <label className="radio-inline" style={{ marginRight: 5 }}><input type="radio" name="formBirthday" id="formBirthday" onChange={() => this.selectOption("formBirthday")} checked={formBirthday} /> In Form Birthday Input</label>
                                    <label className="radio-inline"><input type="radio" name="formBirthday" id="formBirthday" onChange={() => this.selectOption("formBirthday")} checked={!formBirthday} /> Page Birthday Input</label>
                                </div>
                                {collectBirthday &&
                                    <div className="form-group">
                                        <label htmlFor="birthdayPlaceHolderText">Birthday Placeholder Text</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which date to enter here</p>
                                        <input value={variables.birthdayPlaceHolderText} className="form-control" type="text" id="birthdayPlaceHolderText" name="birthdayPlaceHolderText" placeholder="Birthday" onChange={this.handleTextChange} />
                                    </div>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="collectPhoneNumber">Collect Phone Number</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY phone number field to the login flow</p>
                                <input type="checkbox" checked={collectPhoneNumber} id="collectPhoneNumber" name="collectPhoneNumber" onClick={() => this.selectOption("collectPhoneNumber")} />
                                {collectPhoneNumber &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="phoneNumberPlaceholderText">Placeholder Text</label>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter the placeholder text you want to appear in the input box</p>
                                            <input placeholder="Phone Number" type="text" id="phoneNumberPlaceholderText" name="phoneNumberPlaceholderText" className="form-control" value={variables.phoneNumberPlaceholderText} onChange={this.handleTextChange} />
                                        </div>
                                        <label className="radio-inline" style={{ marginRight: 5 }}><input type="radio" name="optionalPhoneNumber" id="optionalPhoneNumber" onChange={() => this.selectOption("optionalPhoneNumber")} checked={optionalPhoneNumber} /> Optional</label>
                                        <label className="radio-inline"><input type="radio" name="optionalPhoneNumber" id="optionalPhoneNumber" onChange={() => this.selectOption("optionalPhoneNumber")} checked={!optionalPhoneNumber} /> Mandatory</label>
                                    </>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="collectOptIn">Turn On Optional Opt-In</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a optional field to the login flow, enter the text below</p>
                                <input type="checkbox" checked={collectOptIn} id="collectOptIn" name="collectOptIn" onClick={() => this.selectOption("collectOptIn")} />
                            </div>
                            {collectOptIn &&
                                <div className="form-group">
                                    <label htmlFor="promotionText">Opt-In Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Text That Shows Next To Opt-In Check Box</p>
                                    <RichTextMarkdown
                                        field={{
                                            id: "promotionText",
                                            name: "promotionText",
                                            value: this.state.promotionText,
                                        }}
                                        form={{
                                            setFieldValue: (field, value) => this.handleRichTextChange('promotionText', value)
                                        }}
                                        placeholder="Opt in to our sweet offer"
                                    />
                                    <input type="checkbox" checked={optInDefaultUnchecked} id="optInDefaultUnchecked" name="optInDefaultUnchecked" onClick={() => this.selectOption("optInDefaultUnchecked")} style={{ marginRight: 5 }} />
                                    <label htmlFor="optInDefaultUnchecked">Default Opt-In Not Checked</label>
                                </div>
                            }
                            {(collectOptIn || collectOptInTwo) &&
                                <div className="form-group">
                                    <label htmlFor="collectOptInTwo">Collect Second Opt-In</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a optional field to the login flow, enter the text below</p>
                                    <input type="checkbox" checked={collectOptInTwo} id="collectOptInTwo" name="collectOptInTwo" onClick={() => this.selectOption("collectOptInTwo")} />
                                </div>
                            }
                            {collectOptInTwo &&
                                <>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Text That Shows Next To Opt-In Check Box</p>
                                    <RichTextMarkdown
                                        field={{
                                            id: "promotionTextTwo",
                                            name: "promotionTextTwo",
                                            value: this.state.promotionTextTwo,
                                        }}
                                        form={{
                                            setFieldValue: (field, value) => this.handleRichTextChange('promotionTextTwo', value)
                                        }}
                                        placeholder="Opt in to our sweet offer"
                                    />
                                    <div className="form-group">
                                        <label htmlFor="collectOptInTwoNotDefaultCheck">Default Not Checked</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to have the Opt-In field not default checked</p>
                                        <input type="checkbox" checked={collectOptInTwoNotDefaultCheck} id="collectOptInTwoNotDefaultCheck" name="collectOptInTwoNotDefaultCheck" onClick={() => this.selectOption("collectOptInTwoNotDefaultCheck")} />
                                    </div>
                                </>
                            }
                            {((collectOptIn && collectOptInTwo) || collectOptInThree) &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="collectOptInTwo">Collect Third Opt-In</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a optional field to the login flow, enter the text below</p>
                                        <input type="checkbox" checked={collectOptInThree} id="collectOptInThree" name="collectOptInThree" onClick={() => this.selectOption("collectOptInThree")} />
                                    </div>
                                    {collectOptInThree &&
                                        <>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Text That Shows Next To Opt-In Check Box</p>
                                            <RichTextMarkdown
                                                field={{
                                                    id: "promotionTextThree",
                                                    name: "promotionTextThree",
                                                    value: this.state.promotionTextThree,
                                                }}
                                                form={{
                                                    setFieldValue: (field, value) => this.handleRichTextChange('promotionTextThree', value)
                                                }}
                                                placeholder="Opt in to our sweet offer"
                                            />
                                            <div className="form-group">
                                                <label htmlFor="collectOptInThreeNotDefaultCheck">Default Not Checked</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to have the Opt-In field not default checked</p>
                                                <input type="checkbox" checked={collectOptInThreeNotDefaultCheck} id="collectOptInThreeNotDefaultCheck" name="collectOptInThreeNotDefaultCheck" onClick={() => this.selectOption("collectOptInThreeNotDefaultCheck")} />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            {((collectOptIn && collectOptInTwo && collectOptInThree) || collectOptInFour) &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="collectOptInFour">Collect Fourth Opt-In</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a optional field to the login flow, enter the text below</p>
                                        <input type="checkbox" checked={collectOptInFour} id="collectOptInFour" name="collectOptInFour" onClick={() => this.selectOption("collectOptInFour")} />
                                    </div>
                                    {collectOptInFour &&
                                        <>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Text That Shows Next To Opt-In Check Box</p>
                                            <RichTextMarkdown
                                                field={{
                                                    id: "promotionTextFour",
                                                    name: "promotionTextFour",
                                                    value: this.state.promotionTextFour,
                                                }}
                                                form={{
                                                    setFieldValue: (field, value) => this.handleRichTextChange('promotionTextFour', value)
                                                }}
                                                placeholder="Opt in to our sweet offer"
                                            />
                                            <div className="form-group">
                                                <label htmlFor="collectOptInFourNotDefaultCheck">Default Not Checked</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to have the Opt-In field not default checked</p>
                                                <input type="checkbox" checked={collectOptInFourNotDefaultCheck} id="collectOptInFourNotDefaultCheck" name="collectOptInFourNotDefaultCheck" onClick={() => this.selectOption("collectOptInFourNotDefaultCheck")} />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            <div className="form-group">
                                <label htmlFor="collectOptIn">Collect Social Handle</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY social handle field to the login flow</p>
                                <input type="checkbox" checked={collectSocialHandle} id="collectSocialHandle" name="collectSocialHandle" onClick={() => this.selectOption("collectSocialHandle")} />
                            </div>
                            {collectSocialHandle &&
                                <div className="form-group">
                                    <label htmlFor="socialHandlePlaceHolderText">Collect Social Handle Placeholder Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which social account handle you want (Facebook, Twitter, Snapchat, TikTok etc)</p>
                                    <input value={variables.socialHandlePlaceHolderText} className="form-control" type="text" id="socialHandlePlaceHolderText" name="socialHandlePlaceHolderText" placeholder="Your Favorite Social Account Handle" onChange={this.handleTextChange} />
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectOptIn">Collect How You Heard About Us</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a how you heard text box</p>
                                <input type="checkbox" checked={collectHowYouHeard} id="collectHowYouHeard" name="collectHowYouHeard" onClick={() => this.selectOption("collectHowYouHeard")} />
                                <div style={{ display: collectHowYouHeard ? "" : "none", margin: 5 }}>
                                    <div className="form-group">
                                        <label htmlFor="howYouHeardPlaceHolderText">Placeholder Text</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter the placeholder text you want to appear in the input box</p>
                                        <input placeholder="How did you hear about this?" type="text" id="howYouHeardPlaceHolderText" name="howYouHeardPlaceHolderText" className="form-control" value={variables.howYouHeardPlaceHolderText} onChange={this.handleTextChange} />
                                    </div>
                                    <div className="form-group">
                                        <label className="radio-inline" style={{ marginRight: 5 }}><input type="radio" name="optionalHowYouHeard" id="optionalHowYouHeard" onChange={() => this.selectOption("optionalHowYouHeard")} checked={optionalHowYouHeard} /> Optional</label>
                                        <label className="radio-inline"><input type="radio" name="optionalHowYouHeard" id="optionalHowYouHeard" onChange={() => this.selectOption("optionalHowYouHeard")} checked={!optionalHowYouHeard} /> Mandatory</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="collectOtherName">Collect Other Name</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY other name field to the login flow</p>
                                <input type="checkbox" checked={collectOtherName} id="collectOtherName" name="collectOtherName" onClick={() => this.selectOption("collectOtherName")} />
                            </div>
                            {collectOtherName &&
                                <div className="form-group">
                                    <label htmlFor="otherNamePlaceHolderText">Collect Other Name Placeholder Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which name to add</p>
                                    <input value={variables.otherNamePlaceHolderText} className="form-control" type="text" id="otherNamePlaceHolderText" name="otherNamePlaceHolderText" placeholder="Other Name" onChange={this.handleTextChange} />
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectPhoto">Collect Photo</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY photo</p>
                                <input type="checkbox" checked={collectPhoto} id="collectPhoto" name="collectPhoto" onClick={() => this.selectOption("collectPhoto")} />
                            </div>
                            {collectPhoto &&
                                <div className="form-group">
                                    <label htmlFor="photoInputLabelText">Collect Photo Label Text</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to label the photo upload input</p>
                                    <input value={variables.photoInputLabelText} className="form-control" type="text" id="photoInputLabelText" name="photoInputLabelText" placeholder="Label for the photo input" onChange={this.handleTextChange} />
                                    <label className="radio-inline" style={{ marginRight: 5 }}><input type="radio" name="optionalPhoto" id="optionalPhoto" onChange={() => this.selectOption("optionalPhoto")} checked={optionalPhoto} /> Optional</label>
                                    <label className="radio-inline"><input type="radio" name="optionalPhoto" id="optionalPhoto" onChange={() => this.selectOption("optionalPhoto")} checked={!optionalPhoto} /> Mandatory</label>
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="collectPhoto">Collect IP Address</label>
                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to collect IP address</p>
                                <input type="checkbox" checked={collectIpAddress} id="collectIpAddress" name="collectIpAddress" onClick={() => this.selectOption("collectIpAddress")} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="showAdvancedSettings">Advanced</label>
                                <br />
                                <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange} />
                            </div>
                            <div style={{ display: this.state.advanced ? "" : "none" }}>
                                <div className="form-group">
                                    <label htmlFor="collectDistance">Turn On/Off Geolocation</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add MANDATORY verification of distance from stadium</p>
                                    <input type="checkbox" checked={collectDistance} id="collectDistance" name="collectDistance" onClick={() => this.selectOption("collectDistance")} />
                                    <span style={{ display: collectDistance ? 'block' : 'none' }}>
                                        <label htmlFor="formattedAddress" style={{ marginTop: 5, marginBottom: 0 }}>Address</label>
                                        <br />
                                        <input className="form-control" type="text" id="formattedAddress" name="formattedAddress" value={formattedAddress} onChange={this.handleChange} placeholder="Address" />
                                        <br />
                                        <label htmlFor="locationPermissionsHeader" style={{ marginTop: 5, marginBottom: 0 }}>Requesting Location Permissions Header</label>
                                        <br />
                                        <input className="form-control" type="text" id="locationPermissionsHeader" name="locationPermissionsHeader" value={locationPermissionsHeader} onChange={this.handleChange} placeholder="Location Permissions Header" />
                                        <br />
                                        <label htmlFor="locationPermissionsBody" style={{ marginTop: 5, marginBottom: 0 }}>Requesting Location Permissions Body</label>
                                        <br />
                                        <textarea className="form-control" id="locationPermissionsBody" name="locationPermissionsBody" value={locationPermissionsBody} onChange={this.handleChange} placeholder="Location Permissions Body" />
                                        <br />
                                        <label htmlFor="notAcceptableLocationHeader" style={{ marginTop: 5, marginBottom: 0 }}>Not Acceptable Location Header</label>
                                        <br />
                                        <input className="form-control" type="text" id="notAcceptableLocationHeader" name="notAcceptableLocationHeader" value={notAcceptableLocationHeader} onChange={this.handleChange} placeholder="Not Acceptable Location Header" />
                                        <br />
                                        <label htmlFor="notAcceptableLocationMessage" style={{ marginTop: 5, marginBottom: 0 }}>Not Acceptable Location Message</label>
                                        <br />
                                        <RichTextMarkdown
                                            field={{
                                                id: "notAcceptableLocationMessage",
                                                name: "notAcceptableLocationMessage",
                                                value: this.state.notAcceptableLocationMessage,
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.setState({ notAcceptableLocationMessage: value })
                                            }}
                                            placeholder="Not Acceptable Location Message"
                                        />
                                        <br />
                                        <label htmlFor="acceptableDistance" style={{ marginTop: 5, marginBottom: 0 }}>Distance (Miles)</label> <input type="checkbox" onClick={() => this.setState({ canPlayOutside: false })} checked={!canPlayOutside} /> Fans Can Play Within <input onClick={() => this.setState({ canPlayOutside: true })} type="checkbox" checked={canPlayOutside} /> Fans Can Play Outside
                                        <br />
                                        <input className="form-control" type="number" id="acceptableDistance" step="0.1" name="acceptableDistance" min="0" value={acceptableDistance} onChange={this.handleChange} placeholder="" />
                                        <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateDistance()}><span className="fa fa-arrow-circle-o-up" /> Update Distance Variables</button></span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="doNotCollectEmail">DO NOT Collect Email</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to remove all email collection from the game</p>
                                    <input type="checkbox" checked={doNotCollectEmail} id="doNotCollectEmail" name="doNotCollectEmail" onClick={() => this.selectOption("doNotCollectEmail")} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="noMandatoryTermsAndConditions">Turn OFF Mandatory Terms And Conditions</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to remove the MANDATORY confirm rules and regs checkbox<br />Rules and Regs Text and Link come from the <a href="/setuprulesandregs"><u>Rules and Regs Tab</u></a></p>
                                    <input type="checkbox" checked={noMandatoryTermsAndConditions} id="noMandatoryTermsAndConditions" name="noMandatoryTermsAndConditions" onClick={() => this.selectOption("noMandatoryTermsAndConditions")} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="sanitizeEmails">Sanitize Emails</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to map accounts with the same sanitized email to the same account<br />(could lead to issues with users having the same email address)</p>
                                    <input type="checkbox" checked={sanitizeEmails} id="sanitizeEmails" name="sanitizeEmails" onClick={() => this.selectOption("sanitizeEmails")} />
                                </div>
                                {collectZipCode &&
                                    <div className="form-group">
                                        <label htmlFor="doNotVerifyAmericanZipCode">Do Not Verify American Zip Code?</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Do not verify American Zip Code. Check this only if the zip codes you are collecting are outside the U.S.</p>
                                        <input type="checkbox" checked={doNotVerifyAmericanZipCode} id="doNotVerifyAmericanZipCode" name="doNotVerifyAmericanZipCode" onClick={() => this.selectOption("doNotVerifyAmericanZipCode")} />
                                    </div>
                                }
                                {collectZipCode && doNotVerifyAmericanZipCode &&
                                    <div className="form-group">
                                        <label htmlFor="verifyCanadianZipCode">Verify Canadian Zip Code?</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Verify Canadian Zip Code. Check this only if the zip codes you are collecting are from Canada</p>
                                        <input type="checkbox" checked={verifyCanadianZipCode} id="verifyCanadianZipCode" name="verifyCanadianZipCode" onClick={() => this.selectOption("verifyCanadianZipCode")} />
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="blockList">Block List Emails/Domains</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter any emails or domains that should be blocked from logging in.</p>
                                    <textarea className="form-control" id="blockList" name="blockList" value={this.state.blockList} onChange={this.handleChange} />
                                    <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateList('block')}><span className="fa fa-arrow-circle-o-up" /> Update Block List</button>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="allowList">Allow List Emails/Domains</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter any emails or domains that should be allowed to login, all other emails/domains will be blocked. This will SUPERSEDE any emails/domains on the block list and let them play</p>
                                    <textarea className="form-control" id="allowList" name="allowList" value={this.state.allowList} onChange={this.handleChange} />
                                    <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateList('allow')}><span className="fa fa-arrow-circle-o-up" /> Update Allow List</button>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="keepFansLoggedIn">Keep Fans Logged In?</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to keep fans logged in so they will not have to keep filling in the login form</p>
                                    <input type="checkbox" checked={keepFansLoggedIn} id="keepFansLoggedIn" name="keepFansLoggedIn" onClick={() => this.selectOption("keepFansLoggedIn")} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectYesNoQuestion">Collect Yes Or No Question</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a Yes No Question To Your Login Form</p>
                                    <input type="checkbox" checked={collectYesNoQuestion} id="collectYesNoQuestion" name="collectYesNoQuestion" onClick={() => this.selectOption("collectYesNoQuestion")} />
                                </div>
                                {collectYesNoQuestion &&
                                    <>
                                        <RichTextMarkdown
                                            field={{
                                                id: "yesNoQuestionText",
                                                name: "yesNoQuestionText",
                                                value: this.state.yesNoQuestionText,
                                            }}
                                            form={{
                                                setFieldValue: (field, value) => this.handleRichTextChange('yesNoQuestionText', value)
                                            }}
                                            placeholder="A great yes or no question"
                                        />
                                        <div className="form-group">
                                            <label htmlFor="collectYesExplanation">Collect Yes Explanation?</label>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a mandatory input for an explanation of why yes</p>
                                            <input type="checkbox" checked={collectYesExplanation} id="collectYesExplanation" name="collectYesExplanation" onClick={() => this.selectOption("collectYesExplanation")} />
                                        </div>
                                        {collectYesExplanation &&
                                            <div className="form-group">
                                                <label htmlFor="yesExplanationPlaceHolderText">Yes Explanation Placeholder Text</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans to explain why yes</p>
                                                <input value={variables.yesExplanationPlaceHolderText} className="form-control" type="text" id="yesExplanationPlaceHolderText" name="yesExplanationPlaceHolderText" placeholder="Why yes?" onChange={this.handleTextChange} />
                                            </div>
                                        }
                                    </>
                                }
                                <div className="form-group">
                                    <label htmlFor="collectTextInput">Collect Paragraph Input</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to be able to ask a question that fans can write a long form response to</p>
                                    <input type="checkbox" checked={collectTextInput} id="collectTextInput" name="collectTextInput" onClick={() => this.selectOption("collectTextInput")} />
                                    {collectTextInput &&
                                        <>
                                            <br />
                                            <label htmlFor="collectTextQuestion" style={{ marginTop: 5, marginBottom: 0 }}>Label For Collect Paragraph Input</label>
                                            <br />
                                            <input className="form-control" type="text" id="collectTextQuestion" name="collectTextQuestion" value={collectTextQuestion} onChange={this.handleTextChange} placeholder="Why are you a great fan?" />
                                        </>
                                    }
                                </div>
                                {collectTextInput &&
                                    <div className="form-group">
                                        <label htmlFor="collectTextInput2">Collect Paragraph Input 2</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to be able to ask a second question that fans can write a long form response to</p>
                                        <input type="checkbox" checked={collectTextInput2} id="collectTextInput2" name="collectTextInput2" onClick={() => this.selectOption("collectTextInput2")} />
                                        {collectTextInput2 &&
                                            <>
                                                <br />
                                                <label htmlFor="collectTextQuestion2" style={{ marginTop: 5, marginBottom: 0 }}>Label For Collect Paragraph Input 2</label>
                                                <br />
                                                <input className="form-control" type="text" id="collectTextQuestion2" name="collectTextQuestion2" value={collectTextQuestion2} onChange={this.handleTextChange} placeholder="Why are you a great fan again?" />
                                            </>
                                        }
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="collectTextInputOne">Collect Text Input</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to be able to ask a question that fans can write a short response to</p>
                                    <input type="checkbox" checked={collectTextInputOne} id="collectTextInputOne" name="collectTextInputOne" onClick={() => this.selectOption("collectTextInputOne")} />
                                    {collectTextInputOne &&
                                        <>
                                            <br />
                                            <label htmlFor="collectTextInputOneLabel" style={{ marginTop: 5, marginBottom: 0 }}>Label For Collect Text Box</label>
                                            <br />
                                            <input className="form-control" type="text" id="collectTextInputOneLabel" name="collectTextInputOneLabel" value={collectTextInputOneLabel} onChange={this.handleTextChange} placeholder="What makes you a great fan?" />
                                        </>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectTextInputTwo">Collect Text Input Two</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to be able to ask a question that fans can write a short response to</p>
                                    <input type="checkbox" checked={collectTextInputTwo} id="collectTextInputTwo" name="collectTextInputTwo" onClick={() => this.selectOption("collectTextInputTwo")} />
                                    {collectTextInputTwo &&
                                        <>
                                            <br />
                                            <label htmlFor="collectTextInputTwoLabel" style={{ marginTop: 5, marginBottom: 0 }}>Label For Collect Text Box</label>
                                            <br />
                                            <input className="form-control" type="text" id="collectTextInputTwoLabel" name="collectTextInputTwoLabel" value={collectTextInputTwoLabel} onChange={this.handleTextChange} placeholder="What makes you the greatest fan?" />
                                        </>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectCustomDropdown">Collect Custom Dropdown</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add custom drop down</p>
                                    <input type="checkbox" checked={collectCustomDropdown} id="collectCustomDropdown" name="collectCustomDropdown" onClick={() => this.selectOption("collectCustomDropdown")} />
                                </div>
                                {collectCustomDropdown &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="customDropdownOptions">Custom Dropdown Options</label>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter any options you want to show up in the dropdown, separate each option with the same separator. For example separating with a comma, space, or line break</p>
                                            <textarea className="form-control" id="customDropdownOptions" name="customDropdownOptions" value={this.state.customDropdownOptions} onChange={this.handleChange} />
                                            <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateCustomDropdownOptions()}><span className="fa fa-arrow-circle-o-up" /> Update Custom Dropdown Options</button>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="customDropdownPlaceholderText">Custom Dropdown Placeholder Text</label>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This is the text that will show up as the first option in the dropdown but will not count as a value</p>
                                            <input value={variables.customDropdownPlaceholderText} className="form-control" type="text" id="customDropdownPlaceholderText" name="customDropdownPlaceholderText" placeholder="" onChange={this.handleTextChange} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container-out mobile-hide" style={{ width: '45%', float: 'right', marginRight: '20px' }}>
                        <div className="admin-form-box" style={{ border: '1px solid black', backgroundColor: 'white' }}>
                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>What Data Should I Gather?</p>
                            <p className="text-muted2">Think carefully about what you want to gather from your fan base.  The more you ask for, the fewer people will be willing to go through the whole logging in process.
                                Balance that with useful parameters that you can use to help your fans get more value from your experience.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpLoginVariables;
